import React from 'react';
import SEO from '../../components/App/SEO';
import Layout from '../../components/App/Layout';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from 'gatsby';
const img = '/img/corporate/BlueMail_Corporate_Contacts.png'

const corporateContactsPage = ({data}) => (
    <Layout  env={data.site.siteMetadata.env}>
        <SEO 
            postTitle='Corporate Contacts | BlueMail App' 
            postDescription='Corporate Contacts Ensure your team can contact who they need, when they need with the ease of Corporate Contacts. Save time with BlueMail Plus&#8217;s support for Global Address List and Active Directory. Not using Microsoft servers? No problem. With the...'
            postImage={img}
            postURL='corporate/corporate-contacts'
            postSEO
        />
        <Navbar />
        <div className="corporate-contacts-area container pt-120 pb-100">
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center'}}>
                    <img src={img} alt='BlueMail Corporate Contacts' style={{width: '100%'}}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center', marginTop: '1.8em'}}>
                        <h1>Corporate Contacts</h1>
                        <hr />
                    </div>
                    <div className='col-12'>
                    <p className="mt-30 center-text">
                        Ensure your team can contact who they need, when they need with the ease of Corporate Contacts. Save time with BlueMail Plus’s support for Global Address List and Active Directory. Not using Microsoft servers? No problem. With the Dashboard, you can manage your corporate address book from one easy to setup and manage location.
                    </p>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
)

export default corporateContactsPage

export const query = graphql`
query CorporateContactsPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`